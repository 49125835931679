import { useState, useRef, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Switch,
} from "@mui/material";
import ClientsUpdate from "./clients-update";
import CustomToolbar from "../customToolbar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import ClientsDetail from "./clients-detail";
import HasPermission from "../../utils/access";
import Error from "../error";

function Clients() {
  // USESTATES & USEREFS
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [update, setUpdate] = useState(false);
  const [view, setView] = useState(false);
  const [page, setPage] = useState(-1);
  const [perPage, setPerPage] = useState(5);
  const [searchFocus, setSearchFocus] = useState(true);
  const [filterFocus, setFilterFocus] = useState(true);
  const [columnField, setColumnField] = useState();
  const [operatorValue, setOperatorValue] = useState();
  const [values, setValue] = useState();
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }]);

  const clientId = useRef();
  const clientName = useRef();
  let clientData = useRef({});

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // QUERY & MUTATION
  const filterOptions = { columnField, operatorValue, values };
  const {
    isLoading: clientsDataLoading,
    data: clientsData,
    refetch,
    error: fetchClientsError,
  } = useQuery(
    ["clients", page, perPage, filterOptions, sortModel],
    () => {
      return axiosPrivate.get(`/clients`, {
        params: {
          page: page,
          per_page: perPage,
          sort: JSON.stringify(sortModel),
          filter:
            filterOptions.columnField === undefined ||
            filterOptions.operatorValue === undefined ||
            filterOptions.values === undefined
              ? []
              : JSON.stringify([
                  {
                    column_field: filterOptions?.columnField,
                    operator_value: filterOptions?.operatorValue,
                    value: filterOptions?.values,
                  },
                ]),
        },
      });
    },
    {
      refetchOnWindowsFocus: false,
      enable: false,
    }
  );
  const {
    mutate: deleteClient,
    isLoading,
    data,
    error,
  } = useMutation((id) => {
    return axiosPrivate.delete(`/clients/${id}`);
  });
  const {
    mutate: revoke,
    data: revokeData,
    error: revokeError,
  } = useMutation((client) => {
    return axiosPrivate.patch(`/clients/${client.id}/status`, {
      status: client?.status,
    });
  });

  // EVENT HANDLERS & HELPERS
  const handleSwitch = (client) => {
    clientId.current = client.id;
    clientData.current = {
      ...clientData.current,
      id: client.id,
      status: client.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };
    revoke(clientData.current);
  };
  const handleEdit = (client) => {
    clientId.current = client.id;
    clientData.current = client;
    setUpdate(true);
    setOpen(true);
    return clientData.current;
  };
  const handleView = (id) => {
    clientId.current = id;
    setView(true);
    setOpen(true);
  };
  const handleDelete = () => {
    deleteClient(clientId.current);
  };
  const handleClick = (client) => {
    setOpenDialog(true);
    clientId.current = client.id;
    clientName.current = client.name;
  };
  const handleDrawer = () => {
    setOpen(false);
    setUpdate(false);
  };
  const handleViewModalClose = () => {
    setOpen(false);
    setView(false);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  const handleOnFilterFocus = () => {
    setFilterFocus(true);
    setSearchFocus(false);
  };
  const handleOnSearchFocus = () => {
    setFilterFocus(false);
    setSearchFocus(true);
  };
  const onFilterChange = useCallback(
    (filterModel) => {
      if (filterFocus && filterModel?.items.length !== 0) {
        filterModel.quickFilterValues = [];
        setColumnField(filterModel?.items[0]?.columnField);
        setOperatorValue(filterModel?.items[0]?.operatorValue);
        setValue(filterModel?.items[0]?.value);
      } else {
        filterModel.items = [];
        setColumnField("*");
        setOperatorValue("contains");
        setValue(
          JSON.stringify({
            columns: [
              "name",
              "logo_url",
              "client_type",
              "scopes",
              "redirect_uris",
            ],
            value: filterModel?.quickFilterValues[0],
          })
        );
      }
    },
    [filterFocus]
  );
  const onSortChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  // USEEFFECT
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully deleted.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      setOpenDialog(false);
      refetch();
    }
  }, [data, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      );
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (values !== undefined) {
      setPage(0);
    }
    if (values?.value !== undefined) {
      if (JSON.parse(values)?.value || values) {
        setPage(0);
      }
    }
  }, [values]);
  useEffect(() => {
    if (revokeData) {
      enqueueSnackbar(`Succesfully changed status.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      setOpenDialog(false);
      refetch();
    }
  }, [revokeData, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (revokeError) {
      enqueueSnackbar(
        revokeError?.response?.data?.error?.message ||
          revokeError?.message ||
          "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      );
    }
  }, [enqueueSnackbar, revokeError]);

  // DATA STRUCTURE
  const columns = [
    { field: "id", headerName: "ID", flex: 0.4 },
    { field: "name", headerName: "Name", flex: 0.2 },
    {
      field: "created_at",
      headerName: "Created At",
      type: "date",
      flex: 0.2,
      valueGetter: (params) => moment(params?.row?.created_at).format("llll"),
    },
    { field: "client_type", headerName: "Clieint Type", flex: 0.2 },
    { field: "logo_url", headerName: "Logo Url", flex: 0.2 },
    { field: "scopes", headerName: "Scopes", flex: 0.2 },
    { field: "redirect_uris", headerName: "Redirect Uris", flex: 0.2 },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 0.2,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              m: 2,
              p: 2,
              bgcolor: params.row.status === "ACTIVE" ? "#e5faea" : "#fde2e2",
              color: params.row.status === "ACTIVE" ? "#56d154" : "#d73936",
              fontSize: 10,
            }}
            id={`${params.row.id}`}
            onClick={() => handleSwitch(params.row)}
            disabled={!HasPermission("update_client_status")}
          >
            {params.row.status}
            <Switch
              checked={params.row.status === "ACTIVE" ? true : false}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#56d154",
                },
              }}
              size="small"
            />
          </Button>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.3,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ flex: 0.2 }}>
            <Button
              sx={{ minWidth: 24, p: 1, color: "black" }}
              onClick={() => handleView(params.row.id)}
              disabled={!HasPermission("get_client")}
            >
              <Visibility />
            </Button>
            <Button
              sx={{ minWidth: 24, p: 1, color: "black" }}
              onClick={() => handleEdit(params.row)}
              disabled={!HasPermission("update_client")}
            >
              <Edit />
            </Button>
            <Button
              sx={{ minWidth: 24, p: 1, color: "red" }}
              onClick={() => handleClick(params.row)}
              disabled={!HasPermission("delete_client")}
            >
              <Delete />
            </Button>
            <div>
              <Dialog
                id="delete-clients-by-id"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ invisible: true }}
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm delete operation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want delete client `{clientName.current}`?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenDialog(false)}
                    sx={{ bgcolor: "#707070", color: "black" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    disabled={isLoading}
                    sx={{ bgcolor: "red", color: "black" }}
                  >
                    Delete
                    {isLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "secondary.main",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Box>
        );
      },
    },
  ];

  // CUSTOM COMPONENT
  function DataGridCustomToolbar() {
    return (
      <CustomToolbar
        searchFocus={searchFocus}
        handleOpen={() => {
          setOpen(true);
          setSearchFocus(false);
          setUpdate(false);
        }}
        handleOnFilterFocus={handleOnFilterFocus}
        handleOnSearchFocus={handleOnSearchFocus}
        page="clients"
        permission="create_client"
      />
    );
  }

  // RENDERING
  return fetchClientsError ? (
    <Error error={fetchClientsError?.response?.data?.error} />
  ) : (
    <Box
      sx={{
        backgroundColor: "#fff",
        flex: 5,
        p: 0,
      }}
    >
      <Modal
        open={open}
        children={
          view ? (
            <ClientsDetail
              handleViewModalClose={handleViewModalClose}
              clientId={clientId.current}
            />
          ) : (
            <ClientsUpdate
              handleModalClose={handleModalClose}
              isNew={!update}
              clientData={clientData.current}
              refetch={refetch}
            />
          )
        }
        onClose={handleDrawer}
      />
      <DataGrid
        sx={{
          pt: 2,
          px: 2,
          fontSize: 12,
          overflow: "hidden",
          resize: "both",
        }}
        rows={clientsData?.data?.data || []}
        columns={columns}
        loading={clientsDataLoading}
        autoHeight
        pagination
        page={page}
        pageSize={perPage}
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowCount={clientsData?.data?.meta_data?.total}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
        sortingMode="server"
        sortModel={sortModel}
        sortingOrder={["desc", "asc"]}
        onSortModelChange={onSortChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        components={{ Toolbar: DataGridCustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 1000 },
          },
        }}
      />
    </Box>
  );
}
export default Clients;
