import { Avatar, Card, CardMedia, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";

//Page Not Found Component
function PageNotFound() {
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 200,
              height: 180,
              borderRadius: 0,
            }}
            alt="logo"
            src={process.env.PUBLIC_URL + "/assets/logo.svg"}
          />
          <Card elevation={0} sx={{ width: "40%", mt: -4 }}>
            <CardMedia
              component="img"
              image={process.env.PUBLIC_URL + "/assets/404-error.jpg"}
              alt="page not found"
            />
          </Card>
        </Box>
      </Container>
    </Grid>
  );
}
export default PageNotFound;
