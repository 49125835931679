import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// Scope Details View Component
const ScopeDetails = ({ handleViewModalClose, scopeName }) => {
  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // QUERY
  const { isLoading, data, isFetching, error } = useQuery(
    ["scopes"],
    () => {
      return axiosPrivate.get(`/oauth/scopes/${scopeName}`);
    },
    {
      refetchOnWindowFocus: false,
      enable: false,
    }
  );

  // USEFFECTS
  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      );
    }
  }, [enqueueSnackbar, error]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          bgcolor: "primary.paper",
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            my: 3,
            mx: 2,
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              View Scope Details
            </Typography>
          </Box>
          <Divider />
          {isLoading || isFetching ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
                mb: 2,
              }}
            >
              <CircularProgress
                size={30}
                sx={{
                  color: "secondary.main",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  my: 0.5,
                  py: 1,
                }}
              >
                <FormLabel>Name:</FormLabel>
                <Typography
                  sx={{ fontWeight: "medium", textAlign: "flex-start", ml: 2 }}
                >
                  {data?.data?.data?.name}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  my: 1,
                  py: 1,
                }}
              >
                <FormLabel>Description:</FormLabel>
                <Typography
                  sx={{ fontWeight: "medium", textAlign: "flex-start", ml: 2 }}
                >
                  {data?.data?.data?.description}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  my: 1,
                  py: 1,
                }}
              >
                <FormLabel>Resource Server Name:</FormLabel>
                <Typography
                  sx={{ fontWeight: "medium", textAlign: "center", ml: 2 }}
                >
                  {data?.data?.data?.resource_server_name}
                </Typography>
              </Box>
              <Divider />
            </>
          )}
          <Divider />
          <Box
            sx={{
              mt: 1.5,
              display: "flex",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{
                ":hover": { bgcolor: "#FFAA00" },
                backgroundColor: "#1c2526",
                color: "#fff",
                textTransform: "none",
              }}
              onClick={handleViewModalClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
};
export default ScopeDetails;
