import { useContext, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import AuthContext from "../context/AuthProvider";
import { useMutation } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

// RP(Relying Party) Logout Request Component
function RPLogout() {
  let { auth, setAuth } = useContext(AuthContext);

  // Get the redirect URI from the requested
  let params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const axiosPrivate = useAxiosPrivate();
  const { mutate: mutateSignOut, isLoading: signOutIsLoading } = useMutation(
    (refreshToken) => {
      return axiosPrivate.post(
        "/logout",
        {
          refresh_token: refreshToken,
        },
        {
          withCredentials: true,
        }
      );
    }
  );

  // USEFFECT
  useEffect(() => {
    if (params.post_logout_redirect_uri) {
      mutateSignOut(auth?.refresh_token, {
        onSuccess: () => {
          window.location.href = params.post_logout_redirect_uri;
          setAuth({});
        },
        onError: () => {
          window.location.href = params.post_logout_redirect_uri;
          setAuth({});
        },
      });
    }
  }, [
    auth?.refresh_token,
    mutateSignOut,
    params.post_logout_redirect_uri,
    setAuth,
  ]);

  // RENDER
  return (
    <>
      {signOutIsLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            minHeight: "40vh",
          }}
        >
          <CircularProgress
            size={40}
            sx={{
              color: "secondary.main",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        </Box>
      )}
    </>
  );
}
export default RPLogout;
