import { Avatar, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

// Error Page Loader Component
function ErrorPageLoader() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 1,
            p: 2,
          }}
        >
          <Avatar
            sx={{
              width: 200,
              height: 180,
              borderRadius: 0,
            }}
            alt="logo"
            src={process.env.PUBLIC_URL + "/assets/logo.svg"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  display: "inline",
                  fontSize: 30,
                  fontWeight: "bold",
                  color: "primary.remove",
                }}
              >
                {"Error."}
              </Typography>
              <Typography
                sx={{
                  display: "inline",
                  fontSize: 30,
                  color: "#9f9b94",
                  ml: 1,
                }}
              >
                {params.error + "."}
              </Typography>
            </Box>
            <Typography
              sx={{ display: "inline", fontSize: 30, color: "#9f9b94" }}
            >
              That's all we know.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default ErrorPageLoader;
