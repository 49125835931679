import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import { Delete, HelpOutline } from '@mui/icons-material';

function AuthorizedApps() {
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // MUTATION & QUERY
    const {
        isLoading: authorizedClientsDataLoading,
        data: authorizedClientsData,
        refetch: authorizedClientsDataRefetch
    } = useQuery(["authorizedClients"], () => {
        return axiosPrivate.get(`oauth/authorizedClients`);
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );
    const {
        isLoading: openIdClientsDataLoading,
        data: openIdClientsData,
        refetch: openIdDataRefetch
    } = useQuery(["openIDAuthorizedClients"], () => {
        return axiosPrivate.get(`oauth/openIDAuthorizedClients`);
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );
    const { mutate, data, error, isLoading } = useMutation((id) => {
        return axiosPrivate.post(`oauth/revokeClient`, { client_id: id });
    });

    // USEEFFECT 
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully revoked access.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            authorizedClientsDataRefetch();
            openIdDataRefetch();
        }
    }, [authorizedClientsDataRefetch, data, enqueueSnackbar, openIdDataRefetch]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error]);

    // RENDERING
    return (
        <CustomTable
            authorizedClientsDataLoading={authorizedClientsDataLoading}
            authorizedClientsRows={authorizedClientsData?.data?.data}
            openIdClientsDataLoading={openIdClientsDataLoading}
            openIdClientsRows={openIdClientsData?.data?.data}
            mutate={mutate}
            loading={isLoading}
        />
    )
}
export default AuthorizedApps;

// CLIENTS WITH ACCOUNT ACCESS TABLE ROW
function AuthorizedClientsRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return props.authorizedClientsDataLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "100vh"
            }}
        >
            <CircularProgress
                size={40}
                sx={{
                    color: "secondary.main",
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        </Box>
    ) : (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
                <TableCell sx={{ mx: 0, pr: 0 }} size="small">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        sx={{ mx: 0, px: 0 }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" >
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <Avatar
                            sx={{ width: 40, height: 40 }}
                            src={row?.logo_url}
                        />
                        <Typography sx={{ fontWeight: "bold", fontSize: "14", ml: 2 }}>
                            {row.name}
                        </Typography>
                        {open &&
                            <Grid container justifyContent="flex-end">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            display: { xs: "none", md: "block" },
                                            mx: 1,
                                            borderRadius: 0,
                                            bgcolor: "primary.remove",
                                            ":hover": { bgcolor: "primary.remove" },
                                            color: "#fff",
                                            textTransform: "none"
                                        }}
                                        onClick={() => props.remove(row.id)}
                                    >
                                        Remove Access
                                    </Button>
                                    <IconButton
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            display: { xs: "block", md: "none" },
                                            mx: 1,
                                            color: "#fff",
                                            textTransform: "none",
                                            borderRadius: 0
                                        }}
                                        onClick={() => props.remove(row.refresh_token)}
                                    >
                                        <Delete size="small" sx={{ color: "primary.remove" }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        }
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: 14, flex: 0.5 }} gutterBottom component="div">
                                Has access to:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                {row.auth_scopes.map((scope) => (
                                    <div key={scope.id}>
                                        <Typography multiple sx={{ fontWeight: "bold", fontSize: 14 }}>
                                            {scope.name}
                                        </Typography>
                                        <Typography multiple sx={{ fontSize: 12, mb: 1 }}>
                                            {scope.description}
                                        </Typography>
                                    </div>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{ margin: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: 14, flex: 0.5 }} gutterBottom component="div">
                                Access given on:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography multiple sx={{ fontSize: 14 }}>
                                    {moment(row.created_at).format("llll")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ margin: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: 14, flex: 0.5 }} gutterBottom component="div">
                                Access expires on:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography multiple sx={{ fontSize: 12 }}>
                                    {moment(row.expires_at).format("llll")}
                                </Typography>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

AuthorizedClientsRow.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

// OPENID CLIENTS TABLE ROW
function OpenIDRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return props.openIdClientsDataLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "100vh"
            }}
        >
            <CircularProgress
                size={40}
                sx={{
                    color: "secondary.main",
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        </Box>
    ) : (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
                <TableCell />
                <TableCell component="th" scope="row" >
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <Avatar
                            sx={{ width: 40, height: 40 }}
                            src={row?.logo_url}
                        />
                        <Typography sx={{ fontWeight: "bold", fontSize: "14", ml: 2 }}>
                            {row.name}
                        </Typography>
                        <Grid container justifyContent="flex-end" sx={{ mr: 2 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        display: { xs: "none", md: "block" },
                                        mx: 1,
                                        bgcolor: "primary.remove",
                                        ":hover": { bgcolor: "primary.remove" },
                                        color: "#fff",
                                        textTransform: "none"
                                    }}
                                    onClick={() => props.remove(row.id)}
                                >
                                    Remove Access
                                </Button>
                                <IconButton
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        display: { xs: "block", md: "none" },
                                        mx: 1,
                                        color: "#fff",
                                        textTransform: "none",
                                        borderRadius: 0
                                    }}
                                    onClick={() => props.remove(row.refresh_token)}
                                >
                                    <Delete size="small" sx={{ color: "primary.remove" }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Box>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

OpenIDRow.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

// CUSTOM TABLE COMPONENT 
function CustomTable(props) {
    return (
        <Box
            sx={{
                display: "flex",
                overflow: "hidden"
            }}
        >
            <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
            <Box
                sx={{
                    width: "100%",
                    maxHeight: "82vh",
                    overflowX: "hidden",
                    overflowY: "auto"
                }}
            >
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{ fontSize: 18, fontWeight: "bold", color: "secondary.main" }}>
                                    Third-party apps with account access
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.authorizedClientsRows?.map((row) => (
                                <AuthorizedClientsRow key={row.name} row={row} remove={props.mutate} removeLoading={props.loading} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>              
            </Grid>
            <Grid item xs={12} md={6}>                
                <Box
                    sx={{
                        width: "100%",
                        maxHeight: "82vh",
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell sx={{ color: "secondary.main", display: "flex" }}>
                                        <Typography sx={{ fontWeight: "bold", fontSize: 18, mr: 1 }}>
                                            Signing with Inverse SSO
                                        </Typography>
                                        <Tooltip
                                            title="Apps listed below will see your personal info, 
                                                including any personal info you've made publicly available.
                                                Will see your primary Inverse SSO Account email address."
                                        >
                                            <HelpOutline />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.openIdClientsRows?.map((row) => (
                                    <OpenIDRow key={row.name} row={row} remove={props.mutate} removeLoading={props.loading} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            </Grid>
        </Box>
    );
}
