import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  CircularProgress,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { BASE_URL, PROFILE_IMAGE_URL, VERSION } from "../../utils/config";
import { Circle } from "@mui/icons-material";
import Error from "../error";

function Consent({ consentId }) {
  const axiosPrivate = useAxiosPrivate();

  // Mutation & Query
  const { isLoading, data, error } = useQuery(["consent"], () => {
    return axiosPrivate.get(`/oauth/consent/${consentId}`)
  },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { mutate, isLoading: approvalLoading, data: approvalData } = useMutation(() => {
    return axiosPrivate.post(
      "/oauth/approveConsent",
      {
        consent_id: consentId,
      },
      {
        withCredentials: true
      }
    );
  });

  const {
    isFetching: profileIsFetching,
    data: profile
  } = useQuery(["user_profile"], () => {
    return axiosPrivate.get(`/profile`);
  },
    {
      refetchOnWindowFocus: false,
      enable: false
    }
  );

  const { mutate: rejectMutate, isLoading: rejectLoading, data: rejectData } = useMutation(() => {
    return axiosPrivate.post(
      "/oauth/rejectConsent",
      {
        consent_id: consentId,
        failure_reason: "User rejected to grant the scopes you requested!"
      },
      {
        withCredentials: true
      }
    );
  });


  // USEEFFECT
  useEffect(() => {
    if (data?.data?.data?.client_trusted) {
      mutate();
    }
  }, [data?.data?.data?.client_trusted, mutate])
  useEffect(() => {
    if (data?.data?.data?.approved) {
      mutate();
    }
  }, [data?.data?.data?.approved, mutate])

  useEffect(() => {
    if (approvalData?.data?.data?.location) {
      window.location.href = approvalData?.data?.data?.location;
    }
  }, [approvalData?.data?.data?.location])

  useEffect(() => {
    if (rejectData?.data?.data?.location) {
      window.location.href = rejectData?.data?.data?.location;
    }
  }, [rejectData?.data?.data?.location])

  // HELPERS
  const onApprove = () => {
    mutate();
  }
  const onReject = () => {
    rejectMutate()
  }

  // RENDER
  if (error) return <Error error={error} />

  if (isLoading || data?.data?.data?.approved) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          minHeight: "40vh",
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: "secondary.main",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      </Box>
    )
  }
  if (approvalLoading && data?.data?.data?.client_trusted) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          minHeight: "40vh",
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: "secondary.main",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      </Box>
    )
  }
  return data?.data?.data && !(data?.data?.data?.client_trusted) && (
    <Dialog
      maxWidth={{ sm: "xs", md: "xs" }}
      open={true}
      sx={{
        "& .MuiDialog-paper": {
          boxShadow: "0px 1px 1px 1px white",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "white"
        },
      }}
      PaperProps={{
        sx: {
          width: { lg: "25%", md: "40%", xs: "100%", sm: "45%" },
          height: "auto",
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Avatar
            size="small"
            src={process.env.PUBLIC_URL + "/assets/logo.svg"}
            sx={{
              width: 24,
              height: 24,
              justifyContent: "center",
              mr: 1,
              borderRadius: 0,
            }}
          />
          <Typography
            sx={{
              display: "inline",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Sign In with Inverse SSO
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent
        sx={{
          px: 0,
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 2
          }}
        >
          {profileIsFetching ?
            (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  minHeight: "40vh"
                }}
              >
                <CircularProgress
                  size={40}
                  sx={{
                    color: "secondary.main",
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 2
                  }}
                >
                  <Avatar
                    sx={{
                      width: "80px",
                      height: "80px",
                      justifyContent: "center",
                      borderRadius: 0,
                    }}
                    src={data?.data?.data?.client_logo}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontSize: 16, textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "primary.main",
                        display: "inline",
                      }}
                    >
                      {data?.data?.data?.client_name}{" "}
                    </Typography>
                    wants to access your Inverse SSO Account
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 1,
                      mt: 0.5
                    }}
                  >
                    <Chip
                      variant="outlined"
                      avatar={
                        <Avatar
                          alt="profile_picture"
                          size="small"
                          sx={{
                            width: 25,
                            height: 25,
                          }}
                          src={`${BASE_URL}/${VERSION}/${PROFILE_IMAGE_URL}/${profile?.data?.data?.profile_picture}`}
                        />
                      }
                      label={`${profile?.data?.data?.first_name}${" " + profile?.data?.data?.middle_name}`}
                      sx={{
                        p: 1,
                        fontWeight: "bold"
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      bgcolor: "whitesmoke",
                      p: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 14, display: "inline" }}
                    >
                      This will allow{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "primary.main",
                        display: "inline",
                      }}
                    >
                      {data?.data?.data?.client_name}{" "}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, display: "inline" }}
                    >
                      to
                    </Typography>
                    {data?.data?.data?.scopes?.map(item => (
                      <ListItem key={item.name}>
                        <Circle
                          sx={{
                            fontSize: 10,
                            color: "primary.main",
                            mr: 0.5,
                            mt: -0.5
                          }}
                        />
                        <ListItemText
                          sx={{
                            color: "inherit",
                            ml: 0.5,
                          }}
                          primary={
                            <Typography
                              sx={{ fontSize: 13 }}
                            >
                              {item.description}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </Box>
                  <Divider />
                </Box>
              </>
            )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            mt: 1,
            display: { md: 'flex', xs: 'none' },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            size="large"
            variant="contained"
            fullWidth
            sx={{
              ":hover": { bgcolor: "#e4e4e4" },
              backgroundColor: "#e4e4e4",
              color: "#1c2526",
              fontWeight: "bold",
              borderRadius: 6,
              mr: 2,
            }}
            onClick={onReject}
          >
            Reject
            {rejectLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "black",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
          <Button
            size="large"
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              ":hover": { bgcolor: "black" },
              backgroundColor: "black",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: 6,
            }}
            onClick={onApprove}
          >
            Approve
            {approvalLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#fff",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
        </Box>
        <Box
          sx={{
            mt: 1,
            display: { xs: 'flex', md: 'none' },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            size="large"
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              ":hover": { bgcolor: "black" },
              backgroundColor: "black",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: 6,
              mb: 2,
            }}
            onClick={onApprove}
            disabled={profileIsFetching}
          >
            Approve
            {approvalLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#fff",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
          <Button
            size="large"
            variant="contained"
            fullWidth
            sx={{
              ":hover": { bgcolor: "#e4e4e4" },
              backgroundColor: "#e4e4e4",
              color: "#1c2526",
              fontWeight: "bold",
              borderRadius: 6,
            }}
            onClick={onReject}
          >
            Reject
            {rejectLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "black",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
export default Consent;