import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { CircularProgress, Grid } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";

function PromptNoneConsent({ consentId, loggedIn }) {
  const axiosPrivate = useAxiosPrivate();

  // Mutation
  const { isLoading, data } = useQuery(
    ["promt_none_consent"],
    () => {
      return loggedIn
        ? axiosPrivate.get(`/oauth/consent/${consentId}`)
        : rejectMutate();
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const {
    mutate,
    isLoading: approvalLoading,
    data: approvalData,
  } = useMutation(() => {
    return axiosPrivate.post(
      "/oauth/approveConsent",
      {
        consent_id: consentId,
      },
      {
        withCredentials: true,
      }
    );
  });

  const {
    mutate: rejectMutate,
    isLoading: rejectLoading,
    data: rejectData,
  } = useMutation(() => {
    return axiosPrivate.post(
      "/oauth/rejectConsent",
      {
        consent_id: consentId,
        failure_reason: "Login is required!",
      },
      {
        withCredentials: true,
      }
    );
  });

  // USEEFFECT
  useEffect(() => {
    if (data?.data?.data?.approved) {
      mutate();
    }
    if ((data && !data?.data?.data?.approved) || !loggedIn) {
      rejectMutate();
    }
  }, [data, loggedIn, mutate, rejectMutate]);

  useEffect(() => {
    if (approvalData?.data?.data?.location) {
      window.location.href = approvalData?.data?.data?.location;
    }
  }, [approvalData?.data?.data?.location]);

  useEffect(() => {
    if (rejectData?.data?.data?.location) {
      window.location.href = rejectData?.data?.data?.location;
    }
  }, [rejectData?.data?.data?.location]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}
        >
          {(isLoading || approvalLoading || rejectLoading) && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  minHeight: "40vh",
                }}
              >
                <CircularProgress
                  size={40}
                  sx={{
                    color: "secondary.main",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Grid>
  );
}
export default PromptNoneConsent;
