import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../App";
import axios from "../../api/axios";

// Forget Password Component
function ForgetPassword(props) {
  const { setOpen } = props;

  // USESTATE & USEREF
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [codeInputType, setCodeInputType] = useState("password");
  const [showCode, setShowCode] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmInputType, setConfirmInputType] = useState("password");
  const [requestOtp, setRequestOtp] = useState(false);

  const password = useRef({});
  const email = useRef({});

  // USEFORM HOOK
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
  });

  email.current = watch("email", "");
  password.current = watch("password", "");

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  const {
    isFetching: otpIsFetching,
    refetch,
    isFetched,
  } = useQuery(
    ["otp-forget-password"],
    () => {
      axios.get("/resetCode", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          email: email.current,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: requestOtp,
      retry: false,
    }
  );
  const { mutate, isLoading, data, error } = useMutation((password) => {
    return axios.post(`/resetPassword`, password);
  });

  // HELPERS
  const handleClick = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    refetch();
  };
  const onReset = (newData) => {
    const toBeSent = { ...newData, email: email.current };
    mutate(toBeSent);
  };

  // USEFFECT
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully changed your password.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      queryClient.clear();
      setOpen(false);
    }
  }, [data, enqueueSnackbar, setOpen]);
  useEffect(() => {
    if (isFetched) {
      enqueueSnackbar(`Code sent.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      setOpenDrawer(true);
      queryClient.clear();
      setRequestOtp(false);
    }
  }, [isFetched, enqueueSnackbar]);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      );
      queryClient.clear();
      setRequestOtp(false);
    }
  }, [enqueueSnackbar, error]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "600px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 1,
            p: 2,
          }}
        >
          <Avatar
            sx={{ width: "140px", height: "100px", m: 1, borderRadius: 0 }}
            src={process.env.PUBLIC_URL + "/assets/logo.svg"}
          />
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            Recover Password
          </Typography>
          <Box sx={{ mt: 1, mx: 1, width: "100%" }}>
            <Divider />
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    my: 1.5,
                  }}
                  fullWidth
                  size="small"
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  helperText={errors?.email ? errors?.email?.message : ""}
                  error={errors?.email}
                  {...field}
                />
              )}
            />
            {openDrawer && email.current && (
              <Dialog open={openDrawer} fullScreen>
                <Grid
                  container
                  sx={{
                    minHeight: "600px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Container component="main" maxWidth="xs">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxShadow: 1,
                        px: 2,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "140px",
                          height: "100px",
                          m: 1,
                          borderRadius: 0,
                        }}
                        src={process.env.PUBLIC_URL + "/assets/logo.svg"}
                      />
                      <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                        Recover Password
                      </Typography>
                      <Box
                        component="form"
                        onSubmit={handleSubmit(onReset)}
                        sx={{ my: 2 }}
                      >
                        <Divider />
                        <Typography sx={{ fontWeight: 20, my: 1 }}>
                          Please put the short code sent via SMS to the phone
                          linked with the email: {email.current} and then set
                          your new password.
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <Divider />
                          <Controller
                            name="reset_code"
                            control={control}
                            rules={{
                              required: "Code can't be empty",
                            }}
                            render={({ field }) => (
                              <TextField
                                id="code"
                                sx={{
                                  mt: 1.5,
                                  mb: 1,
                                }}
                                label="Code"
                                fullWidth
                                type={codeInputType}
                                autoComplete="reset_code"
                                error={!!errors?.reset_code}
                                helperText={
                                  errors?.reset_code
                                    ? errors.reset_code.message
                                    : null
                                }
                                size="small"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setShowCode(!showCode);
                                          setCodeInputType(
                                            showCode === false
                                              ? "text"
                                              : "password"
                                          );
                                        }}
                                      >
                                        {showCode ? (
                                          <Visibility
                                            sx={{ fontSize: "inherit" }}
                                          />
                                        ) : (
                                          <VisibilityOff
                                            sx={{ fontSize: "inherit" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <Controller
                            name="password"
                            control={control}
                            rules={{
                              required: "Password is required",
                              minLength: {
                                value: 6,
                                message: "At least 6 characters is required.",
                              },
                              maxLength: {
                                value: 32,
                                message:
                                  "The maximum characters allowed is 32.",
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                id="new-password-input"
                                sx={{
                                  mt: 0.5,
                                  mb: 1,
                                }}
                                label="New Password"
                                fullWidth
                                type={inputType}
                                autoComplete="new-password"
                                error={!!errors?.password}
                                helperText={
                                  errors?.password
                                    ? errors.password.message
                                    : null
                                }
                                size="small"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setShowPassword(
                                            showPassword === false
                                              ? true
                                              : false
                                          );
                                          setInputType(
                                            showPassword === false
                                              ? "text"
                                              : "password"
                                          );
                                        }}
                                      >
                                        {showPassword ? (
                                          <Visibility
                                            sx={{ fontSize: "inherit" }}
                                          />
                                        ) : (
                                          <VisibilityOff
                                            sx={{ fontSize: "inherit" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <Controller
                            name="confirm_password"
                            control={control}
                            rules={{
                              validate: (value) =>
                                value === password.current ||
                                "The passwords do not match",
                            }}
                            render={({ field }) => (
                              <TextField
                                id="confirm-password-input"
                                sx={{
                                  mt: 0.5,
                                  mb: 1.5,
                                }}
                                label="Confirm Password"
                                fullWidth
                                type={confirmInputType}
                                autoComplete="confirm-current-password"
                                error={!!errors?.confirm_password}
                                helperText={
                                  errors?.confirm_password
                                    ? errors.confirm_password.message
                                    : null
                                }
                                size="small"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setShowConfirmPassword(
                                            showConfirmPassword === false
                                              ? true
                                              : false
                                          );
                                          setConfirmInputType(
                                            showConfirmPassword === false
                                              ? "text"
                                              : "password"
                                          );
                                        }}
                                      >
                                        {showConfirmPassword ? (
                                          <Visibility
                                            sx={{ fontSize: "inherit" }}
                                          />
                                        ) : (
                                          <VisibilityOff
                                            sx={{ fontSize: "inherit" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <Divider />
                          <Button
                            size="small"
                            variant="text"
                            type="text"
                            sx={{
                              ":hover": { bgcolor: "#fff" },
                              backgroundColor: "#fff",
                              color: "primary.main",
                              variant: "body2",
                            }}
                            onClick={() => setRequestOtp(true)}
                            disabled={otpIsFetching}
                          >
                            Resend OTP
                            {otpIsFetching && (
                              <CircularProgress
                                size={24}
                                sx={{
                                  color: "secondary.main",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  marginTop: "-12px",
                                  marginLeft: "-12px",
                                }}
                              />
                            )}
                          </Button>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            my: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              ":hover": { bgcolor: "#FFAA00" },
                              backgroundColor: "#1c2526",
                              color: "#fff",
                            }}
                            onClick={() => setOpenDrawer(false)}
                          >
                            Back
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            sx={{
                              ":hover": { bgcolor: "secondary.main" },
                              backgroundColor: "primary.main",
                              color: "#fff",
                            }}
                            onClick={(formData) => onReset(formData)}
                            disabled={isLoading}
                          >
                            Submit
                            {isLoading && (
                              <CircularProgress
                                size={24}
                                sx={{
                                  color: "primary.paper",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  marginTop: "-12px",
                                  marginLeft: "-12px",
                                }}
                              />
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Container>
                </Grid>
              </Dialog>
            )}
            <Divider />
            <Box
              sx={{
                my: 1.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  ":hover": { bgcolor: "#1c2526" },
                  backgroundColor: "#1c2526",
                  color: "#fff",
                }}
                onClick={handleClick}
              >
                Back
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  textTransform: "none",
                  ":hover": { bgcolor: "primary.main" },
                  color: "#fff",
                }}
                onClick={() => onSubmit()}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default ForgetPassword;
