import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { Add, Delete } from "@mui/icons-material";

function ClientsUpdate({ handleModalClose, isNew, clientData, refetch }) {
  // HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: isNew
      ? {}
      : {
          ...clientData,
          scopes: clientData?.scopes?.split(" "),
        },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "redirect_uris",
  });

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // MUTATION & QUERY
  const { isLoading: scopesDataLoading, data: scopesData } = useQuery(
    ["scopes"],
    () => {
      return axiosPrivate.get(`/oauth/scopes`, {
        params: {
          page: -1,
        },
      });
    },
    {
      refetchOnWindowsFocus: false,
      enable: false,
    }
  );

  const { mutate, isLoading, data, error } = useMutation((formData) => {
    return axiosPrivate.post(`/clients`, formData);
  });

  const {
    mutate: update,
    isLoading: updateIsLoading,
    data: updateData,
    error: updateError,
  } = useMutation((formData) => {
    return axiosPrivate.put(`/clients/${clientData.id}`, formData);
  });

  // HELPERS
  const onSubmit = (formData) => {
    formData = { ...formData, scopes: formData.scopes.join(" ") };
    isNew ? mutate(formData) : update(formData);
  };

  // USEEFFECT
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully created a new client.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      handleModalClose();
      refetch();
    }
    if (updateData) {
      enqueueSnackbar(`Succesfully updated.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      handleModalClose();
      refetch();
    }
  }, [data, enqueueSnackbar, handleModalClose, refetch, updateData]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          }
        );
      }
    }
    if (updateError) {
      if (updateError?.response?.data?.error?.field_error?.length > 0) {
        updateError?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          });
        });
      } else {
        enqueueSnackbar(
          updateError?.response?.data?.error?.message ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          }
        );
      }
    }
  }, [enqueueSnackbar, error, updateError]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          bgcolor: "primary.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            my: 2,
          }}
        >
          {isNew ? (
            <>
              <Typography
                sx={{ fontSize: 26, fontWeight: "bold", textAlign: "center" }}
              >
                {" "}
                Add New Client
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{ fontSize: 26, fontWeight: "bold", textAlign: "center" }}
              >
                {" "}
                Update Client Data
              </Typography>
            </>
          )}
          <Divider />
          <Box
            sx={{
              maxHeight: "80vh",
              overflowY: "visible",
              overflowX: "hidden",
            }}
          >
            <Box
              sx={{
                mx: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Name"
                    fullWidth
                    autoComplete="name"
                    error={!!errors?.name}
                    helperText={errors?.name ? errors.name.message : null}
                    size="small"
                    sx={{
                      mt: 1.5,
                      mb: 0.5,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="client_type"
                control={control}
                rules={{
                  required: "Client type is required.",
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Client Type"
                    margin="normal"
                    fullWidth
                    select
                    size="small"
                    sx={{
                      mt: 1.5,
                      mb: 0.5,
                    }}
                    autoComplete="client_type"
                    autoFocus
                    error={!!errors?.client_type}
                    helperText={
                      errors?.client_type ? errors.client_type.message : null
                    }
                    {...field}
                  >
                    <MenuItem value="confidential">{"confidential"}</MenuItem>
                    <MenuItem value="public">{"public"}</MenuItem>
                  </TextField>
                )}
              />
              <Controller
                name="logo_url"
                control={control}
                rules={{
                  required: "Logo url is required.",
                  pattern: {
                    value:
                      /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                    message: "Invalid url",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Logo URL"
                    margin="normal"
                    fullWidth
                    size="small"
                    sx={{
                      mt: 1.5,
                      mb: 0.5,
                    }}
                    autoComplete="logo_url"
                    autoFocus
                    error={!!errors?.logo_url}
                    helperText={
                      errors?.logo_url ? errors.logo_url.message : null
                    }
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                rules={{
                  required: "Scope is required.",
                }}
                name="scopes"
                fullWidth
                defaultValue={[]}
                render={({ field }) => (
                  <FormControl margin="normal" size="small">
                    <InputLabel id="scopes">Scopes</InputLabel>
                    <Select
                      fullWidth
                      {...field}
                      labelId="scopes"
                      label="scopes"
                      multiple
                      MenuProps={{
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "primary.main",
                          },
                        },
                      }}
                    >
                      {!scopesDataLoading &&
                        scopesData?.data?.data?.map((scope) => (
                          <MenuItem value={scope.name} key={scope.id} fullWidth>
                            {scope.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              />
              {fields.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mr: 1,
                  }}
                >
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Controller
                      key={item.id}
                      name={`redirect_uris[${index}]`}
                      control={control}
                      rules={{
                        required: "Redirect URI can't be empty.",
                        pattern: {
                          value:
                            /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                          message: "Invalid redirect uri",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          id="redirect_uris"
                          key={index}
                          variant="outlined"
                          multiline
                          label={`Redirect URI ${index + 1}`}
                          margin="normal"
                          fullWidth
                          error={!!errors?.redirect_uris?.[index]}
                          helperText={
                            errors?.redirect_uris?.[index]
                              ? errors.redirect_uris?.[index]?.message
                              : null
                          }
                          size="small"
                          sx={{
                            mt: 1.5,
                            mb: 0.5,
                            mr: -2,
                          }}
                          {...field}
                        />
                      )}
                    />
                    <Button
                      sx={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                        mr: -1.5,
                      }}
                      onClick={() => remove(index)}
                    >
                      <Delete sx={{ color: "secondary.main" }} />
                    </Button>
                  </Box>
                </Box>
              ))}
              <Box
                sx={{
                  boxShadow: 1,
                  width: "52%",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  mt: 1.5,
                  mb: 2,
                }}
              >
                <Button
                  sx={{
                    color: "secondary.main",
                  }}
                  onClick={() => append()}
                >
                  <Add sx={{ mr: 0.5 }} />
                  Add Redirect Uri
                </Button>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                ":hover": { bgcolor: "#FFAA00" },
                backgroundColor: "#1c2526",
                color: "#fff",
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                bgcolor: "#fdbc3d",
                ":hover": { bgcolor: "#1c2526" },
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              <Typography sx={{ color: "#fff" }}>
                {isNew ? "Add" : "Update"}
              </Typography>
              {(isLoading || updateIsLoading) && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "secondary.main",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default ClientsUpdate;
