import {
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  Divider,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Slide,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import {
  AdminPanelSettings,
  ChevronLeft,
  Fence,
  Handshake,
  IntegrationInstructions,
  Logout,
  ManageAccounts,
  Person,
  Security,
  Storage,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import AuthContext from "../context/AuthProvider";
import { BASE_URL, PROFILE_IMAGE_URL, VERSION } from "../utils/config";
import HasPermission from "../utils/access";
import { queryClient } from "../App";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(!open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Main() {
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth, setPermissions, setRole } = useContext(AuthContext);

  const [mobileOpen, setMobileOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const {
    isLoading: profileLoading,
    data: profile,
    refetch,
  } = useQuery(
    ["profile"],
    () => {
      return axiosPrivate.get(`/profile`);
    },
    {
      refetchOnWindowFocus: false,
      enable: false,
    }
  );
  const { mutate: mutateSignOut, isLoading: signOutIsLoading } = useMutation(
    (refreshToken) => {
      return axiosPrivate.post(
        "/logout",
        {
          refresh_token: refreshToken,
        },
        {
          withCredentials: true,
        }
      );
    }
  );
  const { data: permissionsData, refetch: refetchPermissions } = useQuery(
    ["user-permission"],
    () => {
      return axiosPrivate.get(`/profile/permissions`);
    },
    {
      refetchOnWindowsFocus: false,
      enable: false,
    }
  );
  const signOut = async (callback) => {
    const refreshToken = auth.refresh_token;
    return mutateSignOut(refreshToken, {
      onSuccess: () => {
        setAuth({});
        queryClient.clear();
        if (callback) {
          callback();
        }
      },
      onError: (error) => {
        console.error({
          error,
          msg: error?.response?.data?.error?.message,
          default: error?.message,
        });
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
            TransitionComponent: Slide,
          }
        );
      },
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setOpen(!open);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    refetch();
  };

  const handleSignOut = () => {
    signOut(() => {
      navigate("/login");
    });
  };

  const Link = ({ to, icon, text }) => (
    <ListItem
      component={NavLink}
      to={to}
      button
      style={
        to === location.pathname
          ? { color: "#FFAA00", backgroundColor: "#1D2426" }
          : { color: "#1D2426" }
      }
    >
      <ListItemIcon sx={{ color: "inherit" }}>
        <Tooltip title={text} arrow placement={open ? "right" : "bottom"}>
          {icon}
        </Tooltip>
      </ListItemIcon>
      <ListItemText sx={{ color: "inherit" }} primary={text} />
    </ListItem>
  );
  const ListItems = [
    {
      link: "/users",
      permission: "get_all_users",
      icon: <Person />,
      text: "Users",
    },
    {
      link: "/roles",
      permission: "get_all_roles",
      icon: <ManageAccounts />,
      text: "Roles",
    },
    {
      link: "/scopes",
      permission: "get_all_scopes",
      icon: <Fence />,
      text: "Scopes",
    },
    {
      link: "/clients",
      permission: "get_all_clients",
      icon: <Handshake />,
      text: "Clients",
    },
    {
      link: "/resource-servers",
      permission: "get_all_resource_servers",
      icon: <Storage />,
      text: "Resource Servers",
    },
    {
      link: "/identity-providers",
      permission: "get_all_identity_providers",
      icon: <Security />,
      text: "Identity Providers",
    },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <DrawerHeader />
      <Divider />
      <Avatar
        variant="square"
        sx={{
          height: "auto",
          width: 100,
          mx: "auto",
          mt: 1,
          transition: (theme) =>
            theme.transitions.create(["width", "height", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
        src={process.env.PUBLIC_URL + "/assets/logo.svg"}
      />
      <List>
        <Link
          to="/"
          icon={<AdminPanelSettings color="inherit" />}
          text="Profile"
        />
        <Link
          to="/authorized-apps"
          icon={<IntegrationInstructions />}
          text="Authorized Apps"
        />
        {ListItems.map(
          (listItem, index) =>
            HasPermission(listItem.permission) && (
              <Link
                key={index}
                to={listItem.link}
                icon={listItem.icon}
                text={listItem.text}
              />
            )
        )}
      </List>
    </Box>
  );

  useEffect(() => {
    if (profile?.data?.data?.role) {
      setRole(profile?.data?.data?.role);
      refetchPermissions();
    }
  }, [profile?.data?.data?.role, refetchPermissions, setRole]);

  useEffect(() => {
    if (permissionsData) {
      setPermissions(permissionsData?.data?.data);
    }
  }, [permissionsData, setPermissions]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" color="secondary" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setOpen(!open);
            }}
            edge="start"
            sx={{
              marginRight: "36px",
              display: { xs: "none", md: "block" },
            }}
          >
            {!open ? <ChevronLeft /> : <MenuIcon />}
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setMobileOpen(!mobileOpen);
            }}
            edge="start"
            sx={{
              marginRight: "36px",
              display: { xs: "block", md: "none" },
            }}
          >
            {!mobileOpen ? <ChevronLeft /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Inverse SSO
          </Typography>
          <Box flex={1} />
          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={handleClick}
          >
            <Avatar
              sx={{ width: 50, height: 50 }}
              src={`${BASE_URL}/${VERSION}/${PROFILE_IMAGE_URL}/${profile?.data?.data?.profile_picture}`}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
                px: 2,
                width: 200,
              }}
            >
              {profileLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "secondary.main",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <>
                  <Avatar
                    sx={{ width: 100, height: 100, mb: 2 }}
                    src={`${BASE_URL}/${VERSION}/${PROFILE_IMAGE_URL}/${profile?.data?.data?.profile_picture}`}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      maxWidth: 200,
                    }}
                    noWrap
                  >
                    {profile?.data?.data?.first_name}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "gray",
                      maxWidth: 200,
                    }}
                    noWrap
                  >
                    {profile?.data?.data?.email}
                  </Typography>
                </>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                mb: 1,
              }}
            >
              <Button
                variant="contained"
                sx={{ bgcolor: "gray", mx: 3, textTransform: "none" }}
                size="small"
                onClick={handleSignOut}
                startIcon={<Logout fontSize="small" />}
                disabled={signOutIsLoading}
              >
                Sign Out
                {signOutIsLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "secondary.main",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ display: { xs: "block", md: "none" } }}>
        <MuiDrawer
          variant="temporary"
          open={!mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </MuiDrawer>
      </Box>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Box>
          <DrawerHeader />
          <Divider />
          <Avatar
            variant="square"
            sx={{
              height: "auto",
              width: !open ? 100 : 50,
              mx: "auto",
              mt: 1,
              transition: (theme) =>
                theme.transitions.create(["width", "height", "margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }}
            src={process.env.PUBLIC_URL + "/assets/logo.svg"}
          />
          <List>
            <Link
              to="/"
              icon={<AdminPanelSettings color="inherit" />}
              text="Profile"
            />
            <Link
              to="/authorized-apps"
              icon={<IntegrationInstructions />}
              text="Authorized Apps"
            />
            {ListItems.map(
              (listItem, index) =>
                HasPermission(listItem.permission) && (
                  <Link
                    key={index}
                    to={listItem.link}
                    icon={listItem.icon}
                    text={listItem.text}
                  />
                )
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: "#F5F5F5" }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
export default Main;
