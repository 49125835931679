import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar-edit";
import { useMutation } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// Profile Picture Update component
const ChangeProfilePicture = (props) => {
  const { setToggle, setFile, refetch, open, setOpen, page } = props;

  // USESTATE
  const [preview, setPreview] = useState("");

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // MUTATION
  const { mutate, isLoading, data, error } = useMutation((image) => {
    return axiosPrivate.post(`assets`, image, {
      "Content-Type": "multipart/form-data",
      type: "formData",
    });
  });

  // EVENT HANDLERS & HELPERS
  const onCrop = (defaultPreview) => {
    setPreview(defaultPreview);
  };

  const onClose = () => {
    setPreview("");
  };

  const onBeforeFileLoad = () => {};

  var formData = new FormData();

  const onSelectPic = () => {
    if (preview !== "") {
      fetch(preview)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png");
          formData.set("asset", file);
          formData.set("type", "profile_picture");
          mutate(formData);
        });
    }
  };

  const onCancelSelect = () => {
    setToggle(false);
  };

  // USEEFFECT
  useEffect(() => {
    if (data) {
      setFile(data?.data?.data);
      setToggle(false);
      if (page === "edit_profile") {
        refetch();
      }
      setOpen(false);
    }
  }, [data, enqueueSnackbar, setToggle, setFile, refetch, setOpen, page]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          }
        );
      }
    }
  }, [enqueueSnackbar, error]);

  // RENDER
  return (
    <Dialog maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#fafafa",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {" "}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Avatar
              imageWidth={270}
              width={270}
              height={50}
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
            />
          </Box>
          <Box sx={{ mt: 2, display: { xs: "none", md: "block" } }}>
            {preview && (
              <img alt="preview" src={preview} width={100} height={100} />
            )}
          </Box>
          <Box sx={{ mt: 1, display: { xs: "block", md: "none" } }}>
            {preview && (
              <img alt="preview" src={preview} width={50} height={50} />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          bgcolor: "#fafafa",
        }}
      >
        <Box
          sx={{
            mx: 1,
            my: 1,
            width: "100%",
            justifyContent: "center",
            display: { xs: "block", md: "none" },
          }}
        >
          <Button
            type="submit"
            onClick={onSelectPic}
            disabled={!preview || isLoading}
            fullWidth
            sx={{
              bgcolor: "#1c2526",
              ":hover": { bgcolor: "#1c2526" },
              borderRadius: 6,
              fontWeight: "bold",
              mb: 1,
            }}
          >
            <Typography sx={{ color: "#fff" }}>
              Ok
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Typography>
          </Button>
          <Button
            onClick={onCancelSelect}
            variant="contained"
            fullWidth
            sx={{
              ":hover": { bgcolor: "#e4e4e4" },
              backgroundColor: "#e4e4e4",
              color: "#1c2526",
              fontWeight: "bold",
              borderRadius: 6,
            }}
          >
            <Typography>Cancel</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            my: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={onCancelSelect}
            sx={{
              ":hover": { bgcolor: "secondary.main" },
              bgcolor: "secondary.main",
              textTransform: "none",
            }}
          >
            <Typography sx={{ color: "primary.paper" }}>Cancel</Typography>
          </Button>
          <Button
            type="submit"
            onClick={onSelectPic}
            disabled={!preview || isLoading}
            size="small"
            sx={{
              ":hover": { bgcolor: "primary.main" },
              bgcolor: "primary.main",
              textTransform: "none",
              color: "primary.paper",
            }}
          >
            <Typography sx={{ color: "primary.paper" }}>Ok</Typography>
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  color: "secondary.main",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeProfilePicture;
